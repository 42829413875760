export const WIRING_INSTRUCTIONS_SWISS = [
  {
    currency: 'EUR',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: 'Avenue Louise 54, Room S52, Brussels 1050 Belgium',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_BANK_SWIFT_BIC',
        value: 'TRWIBEB1XXX',
      },
      {
        name: 'DONATE_IBAN',
        value: 'BE71 9671 0973 6769',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '1097367',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrasse 10, 3608 Thun, Switzerland',
      },
    ],
  },
  {
    currency: 'GBP',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrase  10, 3608 Thun, CH',
      },
      {
        name: 'DONATE_SORT_CODE',
        value: '23-14-70',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '79172055',
      },
      {
        name: 'DONATE_IBAN',
        value: 'GB61 TRWI 2314 7079 1720 55',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '56 Shoreditch High Street, London, E1 6JJ United Kingdom',
      },
    ],
  },
  {
    currency: 'CAD',
    fields: [
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_INSTITUTION_NUMBER',
        value: '621',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '200110173566',
      },
      {
        name: 'DONATE_TRNASIT_NUMBER',
        value: '16001',
      },
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '99 Bank Street, Suite 1420, Ottawa ON K1P 1H4, Canada',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrasse 10, 3608 Thun, Switzerland',
      },
      {
        name: 'DONATE_NOTE',
        value:
          'Domestic wire transfers, Interac, and Online Bill Transfers (OBTs) are not yet supported. Incoming payments take 1-2 working days to be added to your account',
      },
    ],
  },
  {
    currency: 'USD',
    instructionsSets: [
      [
        {
          name: 'DONATE_BANK_NAME',
          value: 'Wise',
        },
        {
          name: 'DONATE_BANK_ADDRESS',
          value: '30 W. 26th Street, Sixth Floor New York NY 10010, United States',
        },
        {
          name: 'DONATE_ACCOUNT_NAME',
          value: 'TrustBridge Global Foundation',
        },
        {
          name: 'DONATE_ACCOUNT_ADDRESS',
          value: 'Zelglistrasse 10 3608 Thun, Switzerland',
        },
        {
          name: 'DONATE_WIRE_ROUTING_NUMBER',
          value: '026073150',
        },
        {
          name: 'DONATE_BANK_SWIFT_BIC',
          value: 'CMFGUS33',
        },
        {
          name: 'DONATE_ACCOUNT_NUMBER',
          value: '8310265949',
        },
        {
          name: 'DONATE_NOTE',
          value: 'You might see Community Federal Savings Bank show up as the bank name. That is OK – the payment will still work.',
        },
      ],
    ],
    bankName: 'UBS AG',
    address: 'Bahnhofstrasse 45, 8098 Zurich, Switzerland',
    accountName: 'TrustBridge Global Foundation',
    swift: 'UBSWCHZH80A',
    iban: 'CH46 0020 6206 4859 1960 A',
    accountNumber: '0206 00485919.60A',
    accountAddress: 'Zelglistrasse 10, 3608 Thun, Switzerland',
  },
  {
    currency: 'CHF',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'UBS AG',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: 'Bahnhofstrasse 45, 8098 Zurich, Switzerland',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_BANK_SWIFT_BIC',
        value: 'UBSWCHZH80A',
      },
      {
        name: 'DONATE_IBAN',
        value: 'CH33 0020 6206 4859 1902 U',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '0206 00485919.02U',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrasse 10, 3608 Thun, Switzerland',
      },
    ],
  },
  {
    currency: 'AUD',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: 'Suite 1, Level 11, 66 Goulburn Street Sydney 2000 Australia',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '206106978',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrasse 10, 3608 Thun, Switzerland',
      },
      {
        name: 'DONATE_BSB_CODE',
        value: '774001',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_NEW_ZEALAND = [
  {
    currency: 'NZD',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '56 Shoreditch High Street, London, E1 6JJ, UK',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '02-1290-0655287-000',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrasse 10, 3608 Thun, Switzerland',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_AUSTRALIA = [
  {
    currency: 'AUD',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: 'Suite 1, Level 11, 66 Goulburn Street Sydney 2000 Australia',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'TrustBridge Global Foundation',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '206106978',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Zelglistrasse 10, 3608 Thun, Switzerland',
      },
      {
        name: 'DONATE_BSB_CODE',
        value: '774001',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_CNCF = [
  {
    currency: 'CAD',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Bank of Nova Scotia',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '44 King Street West, Toronto, ON, M5H 1H1 (Swift code), or PO Box 4234 Stn A, Toronto, ON, M5W 5P6 (Clearing code)',
      },
      {
        name: 'DONATE_BANK_SWIFT',
        value: 'NOSCATT',
      },
      {
        name: 'DONATE_CLEARING_CODE',
        value: 'CC0002 47886',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'Canadian National Christian Foundation',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '00166 00602 16',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: '89 Auriga Drive, Ottawa, ON K2E 7Z2',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_EME = [
  {
    currency: 'AUD',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Australia New Zealand Bank (ANZ)',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '10 Main Street, Box Hill, Australia, 3128',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'M E Foundation for Aid and Relief',
      },
      {
        name: 'DONATE_BANK_SWIFT_BIC',
        value: 'ANZBAU3M',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '013225460261674',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_IGF = [
  {
    currency: 'USD',
    fields: [
      {
        name: 'DONATE_ROUTING_NUMBER',
        value: '061100606',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '1016761775',
      },
      {
        name: 'DONATE_BANK_NAME',
        value: 'Synovus Bank',
      },
      {
        name: 'DONATE_ACCOUNT_NAME',
        value: 'International Generosity Foundation Trust',
      },
      {
        name: 'DONATE_RECEIVING_BANK_ADDRESS',
        value: '1148 Broadway, Columbus, GA 31901',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_GLF = [
  {
    currency: 'USD',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'Wise',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '30 W. 26th Street, Sixth Floor New York NY 10010, United States',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'GIVERS LEGACY FOUNDATION, INC.',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: '1901 Ulmerton Road, Suite 400, Clearwater, FL 33762',
      },
      {
        name: 'DONATE_ACH_WIRE_ROUTING_NUMBER',
        value: '026073150',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '822000080496',
      },
      {
        name: 'DONATE_ACCOUNT_TYPE',
        value: 'Checking',
      },
      {
        name: 'DONATE_NOTE',
        value: 'You might see Community Federal Savings Bank show up as the bank name. That is OK – the payment will still work.',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_SINNGEBER = [
  {
    currency: 'EUR',
    fields: [
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'Hoffnungsträger Stiftung',
      },
      {
        name: 'DONATE_IBAN',
        value: 'DE97600400710530590900',
      },
      {
        name: 'DONATE_BIC',
        value: 'COBADEFFXXX',
      },
      {
        name: 'DONATE_BANK_NAME',
        value: 'Commerzbank Stuttgart',
      },
    ],
  },
];

export const WIRING_INSTRUCTIONS_TOL = [
  // Tax Ded = YES
  {
    currency: 'ZAR',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'RMB Private Bank',
      },
      {
        name: 'DONATE_BRANCH_NAME',
        value: 'Stellenbosch',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '43 Plein Street, Stellenbosch, 7600',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'True South Foundation Trust',
      },
      {
        name: 'DONATE_BRANCH_CODE',
        value: '250408',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '62644363161',
      },
      {
        name: 'DONATE_BANK_SWIFT',
        value: 'FIRNZAJJ',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Unit F12 Willowbridge Centre, 39 Carl Cronjé Drive, Bellville, 7530',
      },
    ],
  },
  // Tax Ded = NO
  {
    currency: 'ZAR',
    fields: [
      {
        name: 'DONATE_BANK_NAME',
        value: 'RMB Private Bank',
      },
      {
        name: 'DONATE_BRANCH_NAME',
        value: 'Stellenbosch',
      },
      {
        name: 'DONATE_BANK_ADDRESS',
        value: '43 Plein Street, Stellenbosch, 7600',
      },
      {
        name: 'DONATE_ACCOUNT_HOLDER',
        value: 'Tree of Life Foundation',
      },
      {
        name: 'DONATE_BRANCH_CODE',
        value: '250408',
      },
      {
        name: 'DONATE_ACCOUNT_NUMBER',
        value: '62295708708',
      },
      {
        name: 'DONATE_BANK_SWIFT',
        value: 'FIRNZAJJ',
      },
      {
        name: 'DONATE_ACCOUNT_ADDRESS',
        value: 'Unit F12 Willowbridge Centre, 39 Carl Cronjé Drive, Bellville, 7530',
      },
    ],
  },
];

export const EFT_INSTRUCTIONS_CNCF = {
  bankName: '002',
  transit: '00166',
  accountNumber: '0060216',
};

export const EFT_INSTRUCTIONS_IGF = {
  bankName: 'Synovus Bank',
  accountName: 'International Generosity Foundation',
  foundation: '',
  routingNumber: '061100606',
  accountNumber: '1016761775',
};

export const EFT_INSTRUCTIONS_GLF = {
  bankName: 'UMB Bank, N.A.',
  accountName: 'Givers Legacy Foundation, Inc.',
  currency: 'USD',
  foundation: 'Givers Legacy Foundation',
  routingNumber: '044000804',
  accountNumber: '8200453111',
};
